import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../../components/Layout'

export default () => {
  const { formatMessage } = useIntl()

  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <h1>{formatMessage({ id: 'contact.thanks.heading' })} 💌</h1>
            <p>{formatMessage({ id: 'contact.thanks.subheading' })}</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}
